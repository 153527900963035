import { Request } from '@/http/request'

export function ctccStatus(parameter: any){
  return Request.axiosInstance.post('/open/number-activation/ctcc-get-number-status', parameter)
}
export function ctccStatusNew(parameter: any){
  return Request.axiosInstance.post('/open/number-activation/ctcc-new-telX-query-status', parameter)
}
export function ctccStatusNew1(parameter: any){
  return Request.axiosInstance.post('/open/api-tool/ctcc-new-telX-query-status', parameter)
}
export function ctccPhone(parameter: any){
  return Request.axiosInstance.post('/open/number-activation/ctcc-activate-phone', parameter)
}
export function ctccPhoneNew(parameter: any){          
  return Request.axiosInstance.post('/open/number-activation/ctcc-new-telX-activation', parameter)
}
export function ctccPhoneNew1(parameter: any){          
  return Request.axiosInstance.post('/open/api-tool/ctcc-new-telX-activation-plus', parameter)
}
export function ctccPhonelink(parameter: any){          
  return Request.axiosInstance.post('/open/api-tool/ctcc-new-get-activate-link', parameter)
}
export function ctccWhiteSms(parameter: any){
  return Request.axiosInstance.post('/open/number-activation/hao-wai-white-sms', parameter)
}
export function dianHuiWhite(parameter: any){
  return Request.axiosInstance.post('/open/number-activation/dian-hui-white', parameter)
}
//增加湖南回拨
export function addHunanCallBack(parameter: any){
  return Request.axiosInstance.post('/open/qty-seat/add-seat', parameter)
}
//增加齐鲁回拨
export function addQiluCallBack(parameter: any){
  return Request.axiosInstance.post('/open/ql-seat/add-seat', parameter)
}

export function ctccNewAutoRecycle(parameter: any){
  return Request.axiosInstance.post('/open/api-tool/ctcc-new-auto-recycle', parameter)
}

export function reportCtccIp(parameter: any){
  return Request.axiosInstance.post('open/api-sip/report-ctcc-ip', parameter)
}
export default {addHunanCallBack,ctccStatus,ctccStatusNew,ctccPhone,ctccPhoneNew, ctccWhiteSms, 
  dianHuiWhite,addQiluCallBack,ctccPhoneNew1,ctccStatusNew1,ctccNewAutoRecycle,ctccPhonelink,
  reportCtccIp }